<template>
  <div class="governance financial" id="financial">
    <div class="ui grid container">
      <div class="intro-content amla">
        <h2>Financial Transparency</h2>
        <div class="paragraph">
          <p>
            <a class="link" href="https://assets.tayocash.com/transparency/TAYOCASH_INC_AFS_FOR_YEAR_ENDED_31_DECEMBER_2023.pdf" download target="_blank">2023 Audited Financial Statements</a>
          </p>
          <p>
            <a class="link" href="https://assets.tayocash.com/transparency/TAYOCASH_INC_2022_AMENDED_AUDITED_FINANCIAL_STATEMENTS.pdf" download target="_blank">2022 Amended Audited Financial Statements</a>
          </p>
          <p>
            <a class="link" href="https://assets.tayocash.com/transparency/TAYOCASH_INC_2021_AUDITED_FINANCIAL_STATEMENTS.pdf" download target="_blank">2021 Audited Financial Statements</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'amla'
}
</script>

<style scoped lang='scss'>
  .container {
    min-height: auto;
  }

  .governance {
    display: flex;
    flex-flow: row;
    align-items: center;
    background: #FFFFFF;
    overflow: hidden;

    .ui.grid {
      min-height: auto !important;
    }
  }
</style>
